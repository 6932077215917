@import "./styles/client-REC-overwrite.scss";
/* Template Configuration */

/* Variables */
$color-base: #605F5E;
$color-secondary: #F6F5F2;
$color-highlight: #BC708B;
$color-highlight-light: #FBECF2;

$bag-svg-cross-color: #fff;
$bag-svg-ellipse-color: #fff;

// Template
$body-background-color: white;

$header-top-header-background: $color-highlight;
$header-top-header-color-text: white;

$header-nav-background-color: white;
$header-nav-submenu-background-color: #fef7f8;
$header-shopping-count-color-text: white;

$input-color-background: $color-highlight-light;
$input-color-text: $color-highlight;
$input-color-border: $color-highlight-light;
$input-color-border-onfocus: $color-highlight;
$input-color-background-onfocus: white;
$input-color-text-placeholder: $color-highlight;

$input-color-background-mobile: $color-highlight-light;
$input-color-background-onfocus-mobile: white;
$input-color-border-mobile: white;

$catalog-menu-color-background: white;
$catalog-menu-color-separator: white;
$catalog-menu-active-color-background: $color-highlight-light;
$catalog-menu-active-color-text: $color-highlight;

$menu-mobile-background-color: $color-highlight;
$menu-mobile-text-color: white;
$menu-mobile-text-background-color: #d48da6;
$menu-mobile-text-background-color-hover: #7d465a;

$submenu-color-background-onhover: inherit;
$submenu-active-color-text: white;

$button-color-text-onhover: white;
$button-color-span-onhover: white;

$form-bullet-title-lock-color: lightgray;

$contact-button-submit-color-background: $color-highlight;
$contact-button-submit-color-background-hover: $color-secondary;
$contact-button-submit-color-text: white;
$contact-button-submit-color-text-hover: $color-base;

$shopping-summary-button-continue-color-background: $color-highlight;
$shopping-summary-button-continue-color-background-hover: white;
$shopping-summary-button-continue-color-text: white;
$shopping-summary-button-continue-color-text-hover: $color-highlight;

$global-button-color-background: $color-highlight-light;
$global-button-color-text: $color-base;
$global-button-color-text-span: $color-highlight;
$global-button-color-background-hover: $color-highlight;
$global-button-color-text-hover: white;
$global-button-color-text-span-hover: white;

// Modal
$modal-color-background: white;
$modal-close-button-color: $color-base;
$modal-close-button-color-hover: $color-highlight;

$modal-button-color-background: $color-highlight-light;
$modal-button-color-text: $color-highlight;
$modal-button-color-border: $color-highlight-light;
$modal-button-color-background-hover: white;
$modal-button-color-text-hover: $color-highlight;
$modal-button-color-border-hover: $color-highlight;

$modal-button-highlight-color-background: $color-highlight;
$modal-button-highlight-color-text: white;
$modal-button-highlight-color-border: $color-highlight;
$modal-button-highlight-color-background-hover: white;
$modal-button-highlight-color-text-hover: $color-highlight;
$modal-button-highlight-color-border-hover: $color-highlight;

$modal-answer-text-color: #999;

// Comment Star
$color-comment-star-no: #dad9e8;
$color-comment-star-yes: #ffc700;

// Logotype
$logotype-max-size: 200px;
$logotype-min-size: 180px;
$logotype-footer-max-size: 120px;

// Cover images
$catalog-cover-img-title-color: white;
$catalog-cover-img-subtitle-color: white;


/* Fonts embedded */
/* BEGIN Thin */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Thin/Roboto-Thin.woff2?v=2.137") format("woff2"), url("../fonts/Thin/Roboto-Thin.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: normal; }
/* BEGIN Thin Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/ThinItalic/Roboto-ThinItalic.woff2?v=2.137") format("woff2"), url("../fonts/ThinItalic/Roboto-ThinItalic.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: italic; }
/* BEGIN Light */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("../fonts/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: normal; }
/* BEGIN Light Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/LightItalic/Roboto-LightItalic.woff2?v=2.137") format("woff2"), url("../fonts/LightItalic/Roboto-LightItalic.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: italic; }
/* BEGIN Regular */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("../fonts/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("../fonts/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("../fonts/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: normal; }
/* BEGIN Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Italic/Roboto-Italic.woff2?v=2.137") format("woff2"), url("../fonts/Italic/Roboto-Italic.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: italic; }
@font-face {
  font-family: Roboto;
  src: url("../fonts/Italic/Roboto-Italic.woff2?v=2.137") format("woff2"), url("../fonts/Italic/Roboto-Italic.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: italic; }
/* BEGIN Medium */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Medium/Roboto-Medium.woff2?v=2.137") format("woff2"), url("../fonts/Medium/Roboto-Medium.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: normal; }
/* BEGIN Medium Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/MediumItalic/Roboto-MediumItalic.woff2?v=2.137") format("woff2"), url("../fonts/MediumItalic/Roboto-MediumItalic.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: italic; }
/* BEGIN Bold */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("../fonts/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("../fonts/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("../fonts/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: normal; }
/* BEGIN Bold Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/BoldItalic/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/BoldItalic/Roboto-BoldItalic.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: italic; }
@font-face {
  font-family: Roboto;
  src: url("../fonts/BoldItalic/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/BoldItalic/Roboto-BoldItalic.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: italic; }
/* BEGIN Black */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("../fonts/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: normal; }
/* BEGIN Black Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/BlackItalic/Roboto-BlackItalic.woff2?v=2.137") format("woff2"), url("../fonts/BlackItalic/Roboto-BlackItalic.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: italic; }
