@import "style-functions";
// @import 'style-config';

header nav .nav-logotype {
  @include mf-sm {
    margin-top: -40px;
    margin-bottom: 0;
    text-align: left;
  }
}

.svg-shopping .a {
  color: #605F5E !important;
}

/* Overwrite Template */
.home .cover img, .contact .cover img, .history .cover img {
  height: inherit !important;
  object-fit: inherit !important;
}
.home .cover .slide-bullets div {
  border: none !important;
}
footer .footer-copy img {
  margin-top: -40px !important;
  max-width: 162px !important;
}

.legal-notice {
  padding-left: 20px !important;
  max-width: 1366px !important;
  margin: 60px auto 0 auto !important;
  text-align: inherit !important;
  h1 {
    padding-bottom: 45px !important;
    font-size: 30px !important;
  }
  .content {

    span {
      font-weight: 405 !important;
    }
    h2 {
      color: #bc708b !important;
      padding-bottom: 20px !important;
      padding-top: 20px !important;
      @include font-size(18px);
    }
  }
}

.terms-of-sales {
  padding-left: 20px !important;
  max-width: 1366px !important;
  margin: 60px auto 0 auto !important;
  text-align: inherit !important;
  h1 {
    padding-bottom: 45px !important;
    font-size: 30px !important;
    text-align:inherit !important;
  }
  .content {
    max-width: 600px !important;
    @include xs {
      max-width:300px !important;
    }
    
    span {
      font-weight: 405 !important;
    }
    h2 {
      color: #bc708b !important;
      padding-bottom: 20px !important;
      padding-top: 20px !important;
      @include font-size(18px);
    }
  }
}
